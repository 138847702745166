import React, {useEffect, useState} from "react";

import HOne from "../h-one";
import StringProcessingService from "../../services/string-processing-service";

import './roi-table.css';

function RoiTable(props){
    let {data, dictionary} = props;
    const stringProcessingService = new StringProcessingService();

    if(data == null || !data.is_display_roi) return null;

    return(
        <div className="InfoBlock RoiBlock">
             <div className={"SectionHdr"}>
                        <HOne title={"ROI Calculation"} align={"left"} addclass={"xsmall"} />
            </div>
            <table className="KeyDealTable">
                <thead>
                        <tr>
                            <td>{dictionary.variable}</td>
                            <td className="Separator"></td>
                            <td className="Value">{dictionary.value}</td>
                        </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{dictionary.rent}</td>
                        <td className="Separator"></td>
                        <td className="Value">${stringProcessingService.convertToFormat(parseFloat(data.roi_calculation.rent).toFixed(0).toString())}</td>
                    </tr>
                    <tr>
                        <td>{dictionary.variable}</td>
                        <td className="Separator"></td>
                        <td className="Value">(${stringProcessingService.convertToFormat(parseFloat(data.roi_calculation.hoa).toFixed(0).toString())})</td>
                    </tr>
                    <tr>
                        <td style={{borderBottom:'none'}}>{dictionary.tax}</td>
                        <td className="Separator"></td>
                        <td style={{borderBottom:'none'}} className="Value">(${stringProcessingService.convertToFormat(parseFloat(data.roi_calculation.tax).toFixed(0).toString())})</td>
                    </tr>

                    <tr>
                        <td className="Line" colSpan={3}></td>
                    </tr>

                    <tr>
                        <td>{dictionary.profit}</td>
                        <td className="Separator"></td>
                        <td className="Value">${stringProcessingService.convertToFormat(parseFloat(data.roi_calculation.profit).toFixed(0).toString())}</td>
                    </tr>
                    <tr>
                        <td>{dictionary.roi}</td>
                        <td className="Separator"></td>
                        <td className="Value">{parseFloat(data.roi_calculation.roi).toFixed(2)}%</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default RoiTable;