import React from 'react';
import ServerApi from '../../services/server-api-service';
import StringProcessingService from '../../services/string-processing-service';
// import DealScore from '../deal-score';
import Description from '../description';
import Information from '../information';
import KeyDealFeatures from '../key-deal-features';
import OffersGrid from '../offers-grid';
import RoiTable from '../roi-table';
import ListingSlider from '../listing-slider';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import HeaderSelection from '../header-selection';
import CookiesManager from '../../services/cookies-manager';
// import AgentCard from '../agent-card';
import ProjectOffersGrid from '../project-offers-grid';

import './selection-listing-page-style.css'
class SelectionListingPage extends React.Component {
  serverApi = new ServerApi();
  stringProcessingService = new StringProcessingService();
  cookiesManager = new CookiesManager();

  constructor(props) {
    super(props);
    this.state = {
      debug: false,
      listingExpiredList: [],
      customTitle: null,
      customDescription: null
    }
    this._isMounted = false;
  }
  //   getSimilar() {
  //     this.serverApi.getSimilar(this.props.match.params.listingId)
  //         .then((data) => {
  //             if (this.state.debug) {
  //                 console.log(`getSimilar data:`, data);
  //             }
  //             this.setState({
  //                 ...this.state,
  //                 similar: data
  //             })
  //         })
  //         .catch((error) => {
  //             console.log(`ERROR: fetch getSimilar ... -> Component: SelectionListingPage ERROR:${error}`);
  //             //this.props.history.replace({pathname:`/page-not-found`});
  //         })
  // }



  getAnalyseInfo() {
    let { dictionary } = this.props;
    this.serverApi.getListingAnalyseInformation(this.props.match.params.listingId)
      .then((data) => {
        if (this.state.debug) {
          console.log(`getListingAnalyseInformation data:`, data);
        }
        if (this._isMounted) {
          this.setState((prevState) => {
            return {
              analyseInfo: data
            }

          })
        }
      })
      .catch((error) => {
        console.log(`ERROR: fetch getAnalyseInforamtion ... -> Component: SelectionListingPage ERROR:${error}`);
        // this.expiredFunc();
        //this.props.history.replace({pathname:`/page-not-found`});
      })
  }

  getSelection() {
    let { selectionId, agentId } = this.props.match.params;
    if (selectionId) {
      this.serverApi.getSelection(selectionId, agentId)
        .then((data) => {
          if (this._isMounted) {
            this.setState((prevState) => {
              let currentMLSListing = data.listings_selected.find(listing => listing.m_l_s_listing_id == this.props.match.params.listingId);


              return {
                similarOffers: data.listings,
                selectionTitle: data.title,
                agent: data.agent,
                notes: data.notes,
                date: data.date,
                offers: data.offers,
                currentMLSListing: currentMLSListing
              }
            })
            //  this.props.saveSelectionListings(data.listings, selectionId, agentId);
            let currentItem;
            let secondaryListings = [];
            if (data.listings != null && Array.isArray(data.listings)) {
              data.listings.map((item, index) => {
                if (item.id != this.props.match.params.listingId) secondaryListings.push(item)
                else currentItem = item
              })
            }

            if (currentItem != null) {
              this.props.saveSelectionListings([currentItem], selectionId, agentId);
              if (currentItem.custom_title != null) {
                this.setState((prevState) => {
                  return {
                    customTitle: currentItem.custom_title,
                    customDescription: currentItem.custom_description
                  }
                })
              }
            }

            let projects = [];
            if(data.offers){
              projects= data.offers.map((offer)=>{
                offer.project.marker.offerId=offer.id;
                return offer.project.marker;
              })
            }


            let initialData = {
              mapSecondaryMarkers: secondaryListings.concat(projects),
              agent: data.agent
            }
            this.props.saveState(initialData);
          }
        })
        .catch((error) => {
          console.log(`ERROR: fetch getSelection ... -> Component: Seletion-Page ERROR:${error}`);
          this.expiredFunc();
          //this.props.history.replace({pathname:`/page-not-found`});
        })
    }
  }

  getListingInformation() {
    this.serverApi.getListingInformation(this.props.match.params.listingId, 'v1.1/')
      .then((requestData) => {
        if (this.state.debug) {
          console.log(`getListingInformation data:`, requestData);
        }
        if (this._isMounted) {
          this.setState((prevState) => {
            return {
              data: requestData,
              currentImg: requestData.Media[0].MediaUrl,
              currentImgId: requestData.Media[0].Order,
            }
          })
          this.props.saveCurrentSelectionListing(requestData);

        }

      })
      .catch((error) => {
        console.log(`ERROR: fetch getListingInformation ... -> Component: SelectionListingPage ERROR:${error}`);
        this.expiredFunc();
        //this.props.history.replace({pathname:`/page-not-found`});
      })
  }

  expiredFunc() {
    let listingExpiredList = []
    let { selectionId, listingId, agentId } = this.props.match.params;
    if (this.cookiesManager.get(selectionId)) {
      listingExpiredList = JSON.parse(this.cookiesManager.get(selectionId));
    }
    listingExpiredList.push(listingId)
    this.cookiesManager.set(selectionId, JSON.stringify(listingExpiredList), 365)

    let pathName = ''
    if (agentId !== undefined && agentId !== null) pathName = `/selection/${selectionId}/${agentId}`;
    else pathName = `/selection/${selectionId}`;
    this.props.history.push(pathName)
    //this.props.history.goBack()
  }



  componentWillMount() {

    this._isMounted = true;
    if (this.cookiesManager.get('exit_pop') === undefined || this.cookiesManager.get('exit_pop') !== '1') {
      this.cookiesManager.set('exit_pop', 1, 365);
    }

    if (this.cookiesManager.get(this.props.match.params.selectionId)) {
      let listingExpiredList = JSON.parse(this.cookiesManager.get(this.props.match.params.selectionId))
      if (listingExpiredList) {
        this.setState({
          listingExpiredList: listingExpiredList
        })
      }
    }

    this.getListingInformation()
    this.getAnalyseInfo();
    this.getSelection();
    let initialData = {
      mapSide: 'left',
      selectionHeader: true,
      showSelectionToolBar: false,
      currentPage: 'SelectionListingPage'
    }
    // this.props.saveState('mapSide', 'left');
    // this.props.saveState('selectionHeader', true);
    // this.props.saveState('showSelectionToolBar', false);
    this.props.saveState(initialData);
    let page = document.getElementsByClassName('Page')[0];
    if (page != null) {
      console.log(page.clientHeight)
      if (this.props.widthRouterMark !== page.clientWidth) {
        this.props.saveState({
          widthRouterMark: page.clientWidth,
          heightRouterMark: page.clientHeight
        })
      }
    }
  }

  componentWillUpdate(prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      let page = document.getElementsByClassName('Page')[0];

      if (page != null) {
        console.log(this.props)

        if (this.props.widthRouterMark !== page.clientWidth) {
          this.props.saveState({
            widthRouterMark: page.clientWidth
          })
        }
      }
    } else {
      if (this.props.widthRouterMark !== 'auto') {
        this.props.saveState({
          widthRouterMark: 'auto'
        })
      }

    }
  }

  componentWillUnmount() {
    this._isMounted = false;

  }

  insertJSONLD() {
    let { analyseInfo, data } = this.state;
    let { dictionary } = this.props;
    if (analyseInfo !== undefined && data !== undefined && dictionary !== undefined) {
      let metaTitle = '';
      if (analyseInfo.rank >= 2) {
        let metaRank = '';
        switch (analyseInfo.rank) {
          case 2: {
            metaRank = dictionary.good_deal;
            break;
          }
          case 3: {
            metaRank = dictionary.great_deal;
            break;
          }
          case 4: {
            metaRank = dictionary.excellent_deal;
            break;
          }
          default: {

          }

        }
        metaTitle = `${metaRank} | ${data.City}, ${data.StateOrProvince} ${data.PostalCode}`;

      } else metaTitle = ``;

      const metaData = `{
      "@context": "https://schema.org/",
      "@type": "Thing",
      "name": "${metaTitle} | Smart Luxe",
      "subjectOf": {
          "@type": "Listing",
          "name": "${metaTitle} | Smart Luxe"
      }
    }`

      if (document.getElementById('productApplactionLd') !== null && document.getElementById('productApplactionLd').textContent !== metaData) {
        document.getElementById('productApplactionLd').remove();
      }


      if (dictionary) {
        const jsonldScript = document.createElement('script');
        jsonldScript.setAttribute('type', 'application/ld+json');
        jsonldScript.setAttribute('id', 'productApplactionLd');




        if ((document.getElementById('productApplactionLd') !== null && document.getElementById('productApplactionLd').textContent !== metaData) ||
          document.getElementById('productApplactionLd') === null) {
          jsonldScript.textContent = metaData;
          document.head.appendChild(jsonldScript);
        }
      }
    }
  }



  handleSwitchMedia(newImage) {
    this.setState({
      currentImgId: newImage.Order,
      currentImg: newImage.MediaUrl
    })
  }

  handleDealScore() {
    let keyDealFeatures = document.getElementsByClassName('KeyDealBlock')[0];
    keyDealFeatures.scrollIntoView({ block: "center", behavior: "smooth" });
  }

  renderMediaSwitch() {
    let data = this.state.data;
    let moreImg = require('../../elements/images/more_image.png')
    return (
      <div className="MediaSwitchDiv">{data.Media.map((item, index) => {
        if (this.state.currentImgId === item.Order && index < 4) {
          return (
            <div key={index}>
              <div className="MediaSwitchItem" onClick={this.handleSwitchMedia.bind(this, item)}>
                <img src={item.MediaUrl} className="MediaImgSmall" alt="" ></img>
              </div>
            </div>
          )
        } else if (index < 4) {
          return (
            <div key={index}>
              <div className="MediaSwitchItem" onClick={this.handleSwitchMedia.bind(this, item)}>
                <img src={item.MediaUrl} className="MediaImgSmall" alt="" ></img>
                <div className="Other"></div>
              </div>
            </div>
          )
        } else if (index === 5) {
          return (
            <div key={index}>
              <div className="MediaSwitchItem">
                <img src={moreImg} className="MediaImgSmall" alt="" ></img>
              </div>
            </div>
          )
        } else {
          return null
        }

      })}</div>
    )

  }

  renderMedia() {
    let { data, analyseInfo } = this.state;
    let { dictionary, width, height, setSliderModal } = this.props;
    if (data !== undefined ) {
      if (this.state.data.Media.length < 4) {
        return (
          <div className={"PropImages"}>
            <div className="MediaCurrentDiv">
              <img className="MediaImgBig" src={this.state.currentImg} alt=""></img>
            </div>
            {this.renderMediaSwitch()}
          </div>
        )
      } else {
        return (
          <div>
            <ListingSlider
              data={data}
              analyseInfo={analyseInfo}
              dictionary={dictionary}
              width={width}
              height={height}
              dealScore={false}
              userForm={false}
              selection={true}
              setSliderModal={setSliderModal}
            />

          </div>
        )
      }
    }

  }

  renderSimilars() {
    let { dictionary, width, agentId } = this.props;
    let { similarOffers, selectionTitle,offers } = this.state;
    let similars = [];
    if (similarOffers) {
      for (let i = 0; i < similarOffers.length; i++) {
        if (similarOffers[i].id !== this.props.match.params.listingId) {
          similars.push(similarOffers[i])
        }
      }

      // similars = this.props.location.state.similarOffers.map((offer,index)=>{
      //   if(offer.id!==this.props.match.params.listingId){
      //     return(offer)
      //   }
      // });

    }



      return (
        <>
        {similars && similars.length > 0 ? 
          <OffersGrid
          selectionId={this.props.match.params.selectionId}
          title={`${dictionary.more_deals}`}
          data={similars}
          width={width}
          dictionary={dictionary}
          lastCard={false}
          agentId={agentId}
          selection={true}
          dealScore={false}
          listingExpiredList={this.state.listingExpiredList}
          />
        : null}
         
        {offers&&
          <ProjectOffersGrid
            width={width} 
            dictionary={dictionary}
            data={offers}
          />
        }
        </>
       
      )

  }

  renderHeaderLine() {
    const contactWA = require('../../elements/images/contactWA.png');
    let { dictionary, width } = this.props;
    let { data, analyseInfo, title, agent, date, notes, selectionTitle, customTitle, customDescription, currentMLSListing} = this.state;
    if (data) {
      let price = data.ListPrice.toString();
      if (data.ClosePrice != null && data.ClosePrice > 0) price = data.ClosePrice.toString();
      let ParsedListingPrice = this.stringProcessingService.convertToFormat(price);

      let ParsedSquareFeet ='';
      if( data.PropertyType !== "Land/Boat Docks" && data.LivingArea){
          ParsedSquareFeet = this.stringProcessingService.convertToFormat(data.LivingArea.toString());
      }else if(data.LotSizeSquareFeet){
          ParsedSquareFeet = this.stringProcessingService.convertToFormat(data.LotSizeSquareFeet.toString());
      }

      // let ParsedSquareFeet = data.PropertyType !== "Land/Boat Docks" ? this.stringProcessingService.convertToFormat(data.LivingArea.toString()) : (
      //   this.stringProcessingService.convertToFormat(data.LotSizeSquareFeet.toString())
      // )
      return (
        <div className="Page SelectionListingPage MenuSpace" style={{ height: window.screen.height - 200 }}>
          <div className={"ListinPageHeaderLine"}>
            {/* <AgentCard
              title={selectionTitle}
              notes={notes}
              agent={agent}
              date={date}
              disableNote={true}
            /> */}

            {agent != null ? (
              <div className="AgentLine">
                <div className="AgentContact">
                  {agent.name}  <a href={`mailto:${agent.email}`}>{agent.email}</a>
                </div>
                <a target="_blank" href={`https://wa.me/${agent.phone}`}><img className="ContactWA" src={contactWA} /></a>
              </div>
            ) : (null)}

            <div className="ListinPageStaticHeader">
              <div className="Title">
                <div className={"VertLine"}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64.484" viewBox="0 0 2 64.484">
                    <defs>
                      <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#a96903" />
                        <stop offset="0.258" stopColor="#aa6a02" />
                        <stop offset="0.352" stopColor="#b07102" />
                        <stop offset="0.418" stopColor="#b97d02" />
                        <stop offset="0.472" stopColor="#c78e01" />
                        <stop offset="0.518" stopColor="#daa401" />
                        <stop offset="0.557" stopColor="#f0be00" />
                        <stop offset="0.579" stopColor="#ffd100" />
                        <stop offset="1" stopColor="#a96903" />
                      </linearGradient>
                    </defs>
                    <g id="Сгруппировать_94" data-name="Сгруппировать 94" transform="translate(-272.933 -2822.173) rotate(90)">
                      <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="64.484" height="2" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)" />
                    </g>
                  </svg>
                </div>
                <div className="TitieAndDisc">
                  <h2>
                    {/* 18555 Collins Avenue, Unit 4803 */}
                    {
                      customTitle != null ? (customTitle) : (`${data.StreetNumber} ${data.StreetName} ${data.UnitNumber}`)
                    }

                  </h2>
                  {`${data.City}, ${data.StateOrProvince} ${data.PostalCode}`}
                </div>

              </div>
              <div className="MainInfoAndDealScoreAtTop">
                <div className="MainInfo">
                  <div className="Item">
                    {dictionary.bedrooms}:   <br /><span>    {data.BedroomsTotal != null && data.BedroomsTotal !== '' ? data.BedroomsTotal : (`N/A`)}  </span>
                  </div>
                  <div className="Item">
                    {dictionary.bathrooms}:  <br /> <span>  {data.BathroomsTotalDecimal != null && data.BathroomsTotalDecimal !== '' ? data.BathroomsTotalDecimal : (`N/A`)}</span>
                  </div>
                  <div className="Item">
                    {dictionary.area_metric_full}:  <br /> <span> {ParsedSquareFeet != null && ParsedSquareFeet !== '' ? ParsedSquareFeet : (`N/A`)}</span>
                  </div>
                  <div className="Item Last PriceInRow Price">
                    <div>
                      {data.ClosePrice != null && data.ClosePrice > 0 ? (dictionary.close_price) : (dictionary.listing_price)}:  <br /> <span>${ParsedListingPrice != null && ParsedListingPrice !== '' ? ParsedListingPrice : (`N/A`)}</span>
                    </div>
                  </div>

                </div>

                {/* <div className={"DealScoreAtTop"} >
                  <DealScore
                    dictionary={dictionary}
                    width={width}
                    visible={true}
                    for_top={true}
                    analyseInfo={analyseInfo}
                    handleDealScore={this.handleDealScore}
                  />
                </div> */}
              </div>
            </div>
          </div>

          <div className="PageBody">

            {this.renderMedia()}

            <div className="ListingPageMainInfoDiv">
              <KeyDealFeatures
                dictionary={dictionary}
                analyseInfo={analyseInfo}
                data={data}
                selection={true}
                width={width}
              />

              <RoiTable
                dictionary={dictionary}
                data={currentMLSListing}
              />


              <Description
                dictionary={dictionary}
                analyseInfo={analyseInfo}
                data={data}
                customDescription={customDescription}
              />

              <Information
                dictionary={dictionary}
                data={data}
                width={width}
                selection={true}
              />

            </div>
            {this.renderSimilars()}

          </div>
        </div>
      )
    }
  }

  render() {
    this.insertJSONLD();
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex"/>
        </Helmet>
        {/* <HeaderSelection dictionary={this.props.dictionary}/> */}
        {this.renderHeaderLine()}
      </>
    )
  };
}

export default withRouter(SelectionListingPage);