import React from 'react';
import GoogleMap from '../google-map';
import './contact-us-page-style.css';
import HOne from "../h-one";

import ServerApi from '../../services/server-api-service';
import FormCheckService from '../../services/form-check-service';
import SeoService from '../../services/seo-service';
import CookiesManager from '../../services/cookies-manager';
import UserForm from '../user-form';
import Helmet from 'react-helmet';
class ContactUsPage extends React.Component {
    formCheckService = new FormCheckService();
    serverApi = new ServerApi();
    seoService = new SeoService();
    cookiesManager = null;
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            contactInfo: {
                userComment: "",
                userEmail: "",
                userFullName: "",
                userPhone: ""
            },
            dataForMap:[{
                latitude:26.013954027878068,
                longitude:-80.17603655784397,
            }],
            contactIsSended: false
        }
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    

    componentWillMount(){
        if(this.props.saveState) this.props.saveState({currentPage:'ContactUsPage'})
    }
    componentDidMount(){
        this.cookiesManager = new CookiesManager();
    }

    handleInput(event) {
        this.setState({
            contactInfo: {
                ...this.state.contactInfo,
                [event.target.name]: event.target.value
            }
        })
    }

    clearForm = ()=>{
        this.setState({
            ...this.state,
            contactInfo:{
                userComment: "",
                userEmail: "",
                userFullName: "",
                userPhone: ""
            }
        })
    }

    handleSubmit() {
        if (!this.formCheckService.checkContactForm(this.state.contactInfo)) {
            this.serverApi.sendForm(this.state.contactInfo)
                .then((data) => {
                    this.cookiesManager.set('exit_pop','1',365)
                    if (this.state.debug) {
                        console.log(data);
            
                    }
                    this.setState({
                        contactIsSended: true
                    })
                })
                .catch((error) => {
                    console.log(`ERROR: fetch sendForm ... -> Component: Contact Us page ERROR:${error}`);
                })
        }
    }

    renderAnswer() {
        const okeyIcon = require('../../elements/icons/okey.png');

        let { dictionary } = this.props;

        if (this.state.contactIsSended) {
            return (
                <div className="ThankYouBlock">

                    <img src={okeyIcon} alt='okey' />
                    {dictionary.thank_you_short}
                </div>
            )
        }
    }

    render() {

        const pageLogo = require('../../elements/images/contact_us.png');
        const iconLetter = require('../../elements/icons/s_letter.png');
        const iconLocation = require('../../elements/icons/s_location.png');
        const iconLinkedIn = require('../../elements/icons/linkedin-icon-logo-svg-vector.svg').default;
        const iconFacebook = require('../../elements/icons/fb_black_icon.svg').default;
        const iconInstagram = require('../../elements/icons/instagram_black_icon.svg').default;
        // const iconLock = require('../../elements/icons/s_secured.png');
        let { dictionary,width,country} = this.props;
        let {contactInfo,dataForMap} = this.state;
        return (
            <div className={"ContactUsPage MenuSpace"}>

                <Helmet>
                    <title> {dictionary.contact_us} | Smart Luxe</title>
                    <meta name="description" content={dictionary.message_us} />
                    {this.seoService.insertHrefLangTags(
                                    {
                                        path: `/contact`,
                                       
                                    })
                                }
                </Helmet>

                <div className="PageLogo">
                    <img id="PageLogoImg" src={pageLogo} alt='Page logo'></img>
                    <div id="PageLogoTitle"><HOne  tag='h1' title={dictionary.contact_us} line={"large"} addclass={"lagresize"} /></div>
                </div>
                <div className="ContactUsForm">

                    <div className="LeftBlock">
                        <div className="FormDiv">
                            <HOne title={dictionary.message_us} align={'left'} />

                            <UserForm
                                clearForm={this.clearForm}
                                topPartRow={true}
                                contactForm={contactInfo}
                                handleInput={this.handleInput}
                                width={width}
                                country={country}
                                thankModal={true}
                                secureInfo={true}
                                dictionary={dictionary}
                                className={'Form'}
                                CustomInputStyle={{ padding:'0 0 0 5px'}}
                                style={{ textAlign: 'center', display:'block', justifyContent: 'center'}} />

                            {/* <button className="Button" id="ButtonPrev" onClick={this.handlePrev}>BACK</button>*/}
                        </div>
                    </div>

                    <div className="RightBlock">
                        <div className={"ContactInfo"}>
                            <HOne title={dictionary.get_in_touch} align={'left'} />
                            <div className="TouchBlock">
                                <span>{dictionary.main_office}</span>
                                <div className="TouchBlockInfo">
                                    <div className="TouchBlockLeft">
                                        <div className="TBItem">
                                            English: &nbsp;<a href='tel:+13057604884'> +1 (305) 760 4884</a>
                                        </div>
                                        <div className="TBItem">
                                            Español: &nbsp;<a href='tel:+13057706124'> +1 (305) 770 6124</a><br />
                                        </div>
                                        <div className="TBItem">
                                            Português: &nbsp;<a href='tel:+13052858518'> +1 (305) 285 8518</a>
                                        </div>

                                        <br/>
                                        <br/>

                                       
                                    </div>
                                    <div className="TouchBlockRight">
                           
                                       

                                        <div className="TBItem">
                                        <div className={"TBItemImage"}><svg xmlns="http://www.w3.org/2000/svg" height="1.4em" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg></div>
                                            <div className={"TBItemText"}><a target="_blank" href="https://wa.me/+13057604884"> +1 (305) 760 4884</a></div>
                                        </div>
                                       

                                        <div className="TBItem">
                                            <div className={"TBItemImage"}><img src={iconLetter}  alt='Icon letter'/></div>
                                            <div className={"TBItemText"}><a href="mailto:info@smartluxe.com"> info@smartluxe.com</a></div>
                                        </div>
                                        <div className="TBItem">
                                            <div className={"TBItemImage"}><img src={iconLocation} alt='Icon location'/></div>
                                            <div className={"TBItemText"}>450 N. Park Rd, Unit 801, <br /> Hollywood, FL 33021</div>
                                        </div>

                                        <div className="TBItem">
                                            <div className={"TBItemImage SvgIcon"}><img src={iconFacebook}  alt='Icon letter'/></div>
                                            <div className={"TBItemText"}><a target='_blank' href="https://www.facebook.com/SmartLuxeRealty"> Facebook</a></div>
                                        </div>

                                        <div className="TBItem">
                                            <div className={"TBItemImage SvgIcon"}><img src={iconInstagram}  alt='Icon letter'/></div>
                                            <div className={"TBItemText"}><a target='_blank' href="https://www.instagram.com/smart.luxe.realty/"> Instagram</a></div>
                                        </div>

                                        <div className="TBItem">
                                            <div className={"TBItemImage"}><img src={iconLinkedIn} className='LinkedInIcon' alt='Icon letter'/></div>
                                            <div className={"TBItemText"}><a target='_blank' href="https://www.linkedin.com/company/100576722/admin/feed/posts/"> LinkedIn</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div>
                    <GoogleMap
                        // markCoords={{
                        //     lat: 26.013954027878068,
                        //     lng: -80.17603655784397
                        // }}
                        data={dataForMap }
                        // coord={{
                        //     lat: 26.013954027878068,
                        //     lng: -80.17603655784397
                        // }}
                    //    lat ={this.state.Latitude}
                    //    lng = {this.state.Longitude}
                    />
                </div>
            </div>
        )
    }

}

export default ContactUsPage;