import React from 'react';

import UserCards from '../user-cards';
import JoinTeamModal from '../join-team-modal';
import ThanksModal from '../thanks-modal';
import ServerApi from '../../services/server-api-service';
import SeoService from '../../services/seo-service';
import Parser from 'html-react-parser';
import './our-team-page-style.css'
import HOne from "../h-one";
import Helmet from 'react-helmet';
class OurTeamPage extends React.Component {
    serverApi = new ServerApi();
    seoService = new SeoService();
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            isVisibleModal: false,
            isVisibleThanksModal: false,
        }
        this.handleModal = this.handleModal.bind(this);
        this.handleThanksModal = this.handleThanksModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentWillMount() {
        if(this.props.saveState) this.props.saveState({currentPage:'OurTeamPage'})
        if (this.state.debug) {
            console.log("componentWillMount ... -> Component: OurTeamPage");
        }

        this.serverApi.getTeamPageInfo()
            .then((data) => {
                if (this.state.debug) {
                    console.log(`getTeamPageInfo data:`, data);
                }
                
                this.setState({
                    ...this.state,
                    pageTeam: data,
                })

                
            })
            .catch((error) => {
                console.log(`ERROR: fetch getTeamPageInfo ... -> Component: OurTeamPage ERROR:${error}`);
            })
    }

    handleModal() {
        this.setState((state) => {
            return {
                isVisibleModal: !state.isVisibleModal,
                isVisibleThanksModal: false
            }
        });
    }

    handleThanksModal() {
        this.setState({
            isVisibleModal: false,
            isVisibleThanksModal: true
        });
    }

    closeModal() {
        this.setState({
            isVisibleModal: false,
            isVisibleThanksModal: false,
        })
    }



    render() {
        const pageLogo = require('../../elements/images/our_team_page.jpg');
        let { dictionary,country} = this.props;
        let {isShortEmail, pageTeam} = this.state;
        let parsedTeamDesc=Parser(dictionary.team_desc)
        return (
            <div className={"OurTeamPage MenuSpace"}>
              <Helmet>
                    <title> {dictionary.our_team} | Smart Luxe</title>
                    <meta name="description" content={parsedTeamDesc[0]} />
                    {this.seoService.insertHrefLangTags(
                                    {
                                        path: `/team`,
                                       
                                    })
                                }
                </Helmet>
                <div className="PageLogo">
                    <img id="PageLogoImg" src={pageLogo} alt='logo'></img>
                    <div id="PageLogoTitle">
                        <HOne tag='h1' title={dictionary.our_team} line={"large"} addclass={"lagresize"} />

                    </div>
                </div>
                <div className="OurTeamPageTopLine">
                    <div className="OurTeamPageTop">
                        {parsedTeamDesc}
                    </div>
                    <div className="OurTeamPageSeparator">
                        <h2>{dictionary.team_separator}</h2>
                    </div>
                </div>
                <div className="OurTeamPageBody">

                    <div className="PageBody">
                     
                        <UserCards
                            data={pageTeam}
                            teamCard={true}
                            dictionary={dictionary}
                            handleModal={this.handleModal}
                        />
                    </div>
                </div>
                <JoinTeamModal
                    dictionary={dictionary}
                    handleModal={this.handleModal}
                    isVisible={this.state.isVisibleModal}
                    country={country}
                    openThanksModal={this.handleThanksModal}
                />
                <ThanksModal
                    isVisible={this.state.isVisibleThanksModal}
                    title={dictionary.thank_you}
                    message={dictionary.thank_you_short}
                    closeModal={this.closeModal}
                />
            </div>
        )
    }

}

export default OurTeamPage;