import React from 'react';
// import GoogleMap from '../google-map';
// import GoogleMapStaticCustom from '../google-map-static-custom';
import ServerApi from '../../services/server-api-service';
import FormCheckService from '../../services/form-check-service';
import StringProcessingService from '../../services/string-processing-service';
import CookiesManager from '../../services/cookies-manager';
import ThanksModal from '../thanks-modal';
// import ListingPageModal from '../listing-page-modal';
import ListingSlider from '../listing-slider';
import Description from '../description';
import Information from '../information';
import KeyDealFeatures from '../key-deal-features';

import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';

import './listing-page-style.css';

import { withRouter } from "react-router";
import OffersGrid from '../offers-grid';
import DealScore from '../deal-score';
import FindMyDealBlock from '../find-my-deal-block/';
import HOne from "../h-one";
import Helmet from 'react-helmet';
import UserForm from '../user-form';
import SeoService from '../../services/seo-service';
import AgentCard from '../agent-card';

class ListingPage extends React.Component {
    stringProcessingService = new StringProcessingService();
    serverApi = new ServerApi()
    formCheckService = new FormCheckService();
    seoService = new SeoService();
    cookiesManager = null;
    constructor(props) {
        super(props);
        this.state = {
            currentImg: '',
            debug: false,
            dealScoreTest: 3,
            currentImgId: '1',
            isVisibleThanksModal: false,
            contactIsSended: false,
            selectedCountry: null,
            contactForm: {
                userFullName: '',
                userPhone: '',
                userEmail: '',
                userMessage: ''
            },
            contactInfo: {
                price_range: 0,
                project: `MLS ${this.props.match.params.id}`,
                userComment: "",
                userEmail: "",
                userFullName: "",
                userPhone: ""
            },
        }
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    getListingCombined() {
        this.serverApi.getSimilar(this.props.match.params.id)
            .then((data) => {
                if (this.state.debug) {
                    console.log(`getSimilar data:`, data);
                }
                let property = data.analyseInfo.rank === 3 || data.analyseInfo.rank === 4 ? 'deal.' : "property.";

                let price_range;
                if (data.listing.ListPrice < 500000) {
                    price_range = 1;
                } else if (data.listing.ListPrice > 500000 && data.listing.ListPrice < 1500000) {
                    price_range = 2;
                } else if (data.listing.ListPrice > 1500000) {
                    price_range = 3;
                }

                this.setState({
                    ...this.state,
                    similar: data,
                    analyseInfo: data.analyseInfo,
                    data: data.listing,
                    contactInfo: {
                        ...this.state.contactInfo,
                        userComment: `${this.props.dictionary.send_me_more_details} ${property}`
                    },
                    currentImg: data.listing.Media[0].MediaUrl,
                    currentImgId: data.listing.Media[0].Order,
                    contactInfo: {
                        ...this.state.contactInfo,
                        price_range: price_range
                    }
                })
            })
            .catch((error) => {
                console.log(`ERROR: fetch getSimilar ... -> Component: ListingPag ERROR:${error}`);
                //this.props.history.replace({pathname:`/page-not-found`});
            })
    }

    getSimilar() {
        this.serverApi.getSimilar(this.props.match.params.id)
            .then((data) => {
                if (this.state.debug) {
                    console.log(`getSimilar data:`, data);
                }
                this.setState({
                    ...this.state,
                    similar: data
                })
            })
            .catch((error) => {
                console.log(`ERROR: fetch getSimilar ... -> Component: ListingPag ERROR:${error}`);
                //this.props.history.replace({pathname:`/page-not-found`});
            })
    }

    getAnalyseInfo() {
        let { dictionary } = this.props;

        this.serverApi.getListingAnalyseInformation(this.props.match.params.id)
            .then((data) => {
                if (this.state.debug) {
                    console.log(`getListingAnalyseInformation data:`, data);
                }
                let property = data.rank === 3 || data.rank === 4 ? `${this.props.dictionary.deal}.` : `${this.props.dictionary.property}.`;
                this.setState({
                    ...this.state,
                    analyseInfo: data,
                    contactInfo: {
                        ...this.state.contactInfo,
                        userComment: `${this.props.dictionary.send_me_more_details} ${property}`
                    }
                })
            })
            .catch((error) => {
                console.log(`ERROR: fetch getAnalyseInforamtion ... -> Component: ListingPag ERROR:${error}`);
                this.setState({
                    ...this.state,
                    contactInfo: {
                        ...this.state.contactInfo,
                        userComment: `${this.props.dictionary.send_me_more_details} ${this.props.dictionary.deal}`
                    }
                })
                //this.props.history.replace({pathname:`/page-not-found`});
            })
    }

    componentWillMount() {
        if (this.props.saveState) this.props.saveState({ currentPage: 'ListingPage' })
        if (this.state.debug) {
            console.log('componentWillMount ... -> Component: ListingPage');
        }

        if (this.props.match.params.agentId) {
            this.serverApi.getTeamPageInfo()
                .then((agentsInfo) => {
                    console.log(agentsInfo.find((item) => item.id == this.props.match.params.agentId))
                    let agent = agentsInfo.find((item) => item.id == this.props.match.params.agentId)
                    this.setState({
                        agent: agent
                    })
                })
                .catch((error) => {
                    console.log(`ERROR: fetch getTeamPageInfo ... -> Component: ListingPag ERROR:${error}`);
                })
        }


        this.serverApi.getListingInformation(this.props.match.params.id, 'v1.1/')
            .then((dataFromReq) => {

                
                if (this.state.debug) {
                    console.log(`getListingInformation data:`, dataFromReq);
                }
                let price_range;
                if (dataFromReq.ListPrice < 500000) {
                    price_range = 1;
                } else if (dataFromReq.ListPrice > 500000 && dataFromReq.ListPrice < 1500000) {
                    price_range = 2;
                } else if (dataFromReq.ListPrice > 1500000) {
                    price_range = 3;
                }
                this.setState({
                    data: dataFromReq,
                    currentImg: dataFromReq.Media[0].MediaUrl,
                    currentImgId: dataFromReq.Media[0].Order,
                    contactInfo: {
                        ...this.state.contactInfo,
                        price_range: price_range
                    }
                })


            })
            .catch((error) => {
                console.log(`ERROR: fetch getListingInformation ... -> Component: ListingPag ERROR:${error}`);
                this.props.history.replace({ pathname: `/page-not-found` });
            })

        this.getAnalyseInfo();
        this.getSimilar();

    }

    insertJSONLD() {
        let { analyseInfo, data } = this.state;
        let { dictionary } = this.props;
        if (data !== undefined && dictionary !== undefined) {
            let metaTitle = '';
            if (analyseInfo != null) {
                if (analyseInfo.rank >= 2) {
                    let metaRank = '';
                    switch (analyseInfo.rank) {
                        case 2: {
                            metaRank = dictionary.good_deal;
                            break;
                        }
                        case 3: {
                            metaRank = dictionary.great_deal;
                            break;
                        }
                        case 4: {
                            metaRank = dictionary.excellent_deal;
                            break;
                        }

                    }
                    metaTitle = `${metaRank} | ${data.City}, ${data.StateOrProvince} ${data.PostalCode}`;

                } else metaTitle = ``;
            } else {
                metaTitle = `${data.City}, ${data.StateOrProvince} ${data.PostalCode}`;
            }


            const metaData = `{
          "@context": "https://schema.org/",
          "@type": "Thing",
          "name": "${metaTitle} | Smart Luxe",
          "subjectOf": {
              "@type": "Listing",
              "name": "${metaTitle} | Smart Luxe"
          }
        }`

            if (document.getElementById('productApplactionLd') !== null && document.getElementById('productApplactionLd').textContent !== metaData) {
                document.getElementById('productApplactionLd').remove();
            }


            if (dictionary) {
                const jsonldScript = document.createElement('script');
                jsonldScript.setAttribute('type', 'application/ld+json');
                jsonldScript.setAttribute('id', 'productApplactionLd');




                if (document.getElementById('productApplactionLd') === null) {
                    jsonldScript.textContent = metaData;
                    document.head.appendChild(jsonldScript);
                }
            }
        }
    }


    closeModal() {
        this.setState({
            isVisibleThanksModal: false,
        })
    }

    clearForm = () => {
        this.setState({
            ...this.state,
            contactInfo: {
                price_range: 0,
                project: `MLS ${this.props.match.params.id}`,
                userComment: "",
                userEmail: "",
                userFullName: "",
                userPhone: ""
            },
        })
    }

    componentDidMount() {
        this.cookiesManager = new CookiesManager();
        this.setState({
            ...this.state,
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    handleSwitchMedia(newImage) {
        this.setState({
            currentImgId: newImage.Order,
            currentImg: newImage.MediaUrl
        })
    }

    handleInput(event) {
        this.setState({
            contactInfo: {
                ...this.state.contactInfo,
                [event.target.name]: event.target.value
            }
        })
    }

    saveCurrentSelectedCountry = (value) => {
        this.setState({
            selectedCountry: value
        })
    }

    handleSubmit(event) {
        if (!this.formCheckService.checkContactForm(this.state.contactInfo)) {
            this.serverApi.sendForm(this.state.contactInfo)
                .then((data) => {
                    this.cookiesManager.set('exit_pop', '1', 365)
                    if (this.state.debug) {
                        console.log(data);
                    }
                    if (event.target.name === "BottomForm") {
                        this.setState({
                            contactIsSended: true
                        })
                    } else {
                        this.setState({
                            isVisibleThanksModal: true
                        })
                    }

                })
                .catch((error) => {
                    console.log(`ERROR: fetch sendForm ... -> Component: Wizard-Page ERROR:${error}`);
                    this.props.history.replace({ pathname: `/page-not-found` });
                })
        }
    }

    openThanksModal = (event) => {
        this.setState({
            isVisibleThanksModal: true
        })
    }

    handleDealScore() {
        let keyDealFeatures = document.getElementsByClassName('KeyDealBlock')[0];
        keyDealFeatures.scrollIntoView({ block: "center", behavior: "smooth" });
    }



    renderMediaSwitch() {
        let data = this.state.data;
        let moreImg = require('../../elements/images/more_image.png')
        return (
            <div className="MediaSwitchDiv">{data.Media.map((item, index) => {
                if (this.state.currentImgId === item.Order && index < 4) {
                    return (
                        <div key={index}>
                            <div className="MediaSwitchItem" onClick={this.handleSwitchMedia.bind(this, item)}>
                                <img src={item.MediaUrl} className="MediaImgSmall" alt="" ></img>
                            </div>
                        </div>
                    )
                } else if (index < 4) {
                    return (
                        <div key={index}>
                            <div className="MediaSwitchItem" onClick={this.handleSwitchMedia.bind(this, item)}>
                                <img src={item.MediaUrl} className="MediaImgSmall" alt="" ></img>
                                <div className="Other"></div>
                            </div>
                        </div>
                    )
                } else if (index === 5) {
                    return (
                        <div key={index}>
                            <div className="MediaSwitchItem">
                                <img src={moreImg} className="MediaImgSmall" alt="" ></img>
                            </div>
                        </div>
                    )
                }

            })}</div>
        )

    }

    renderAnswer() {
        let { dictionary } = this.props;
        const okeyIcon = require('../../elements//icons/okey.png')
        if (this.state.contactIsSended) {
            return (
                <div>
                    <img src={okeyIcon} className="IconFormSmall" alt=''></img>
                    {dictionary.thank_you_short}
                </div>
            );
        }
    }

    renderMedia() {
        let { data, analyseInfo } = this.state;
        let { dictionary, width, height, country } = this.props;
        if (this.state.data.Media.length <= 4) {
            return (
                <div className={"PropImages"}>
                    <div className="MediaCurrentDiv">
                        <img className="MediaImgBig" src={this.state.currentImg} alt=""></img>
                    </div>
                    {this.renderMediaSwitch()}
                </div>
            )
        } else {
            return (
                <div>
                    <ListingSlider
                        country={country}
                        data={data}
                        analyseInfo={analyseInfo}
                        dictionary={dictionary}
                        width={width}
                        height={height}
                    />
                </div>
            )
        }

    }

    renderDealScoreContactForm() {
        let { dictionary, width, country } = this.props;
        let { contactInfo, selectedCountry, agent,analyseInfo,data } = this.state;
        const iconLock = require('../../elements/icons/s_secured.png');
        const contactWA = require('../../elements/images/contactWA.png');

        if(!analyseInfo) return (
            <div className={"DealScoreContact"}>
                <HOne title={dictionary.request_contact} addclass={"xsmall"} />
  

                <UserForm
                    clearForm={this.clearForm}
                    contactForm={contactInfo}
                    handleInput={this.handleInput}
                    selectedCountry={selectedCountry}
                    width={width}
                    saveCurrentSelectedCountry={this.saveCurrentSelectedCountry}
                    country={country}
                    secureInfo={true}
                    dictionary={dictionary}
                    openThanksModal={this.openThanksModal}
                    style={{ textAlign: 'center', margin: '40px', justifyContent: 'center' }}
                />
            </div>);
        return (
            <>
                {agent ? (
                    <AgentCard
                        listing={data}
                        hugeProfile={analyseInfo.rank < 2 ? true : false} 
                        width={width}
                        title={'1'}
                        notes={''}
                        agent={agent}
                        date={''}
                        disableNote={true}
                    />
                )
                    : (
                        <div className={"DealScoreContact"}>
                            <HOne title={dictionary.request_contact} addclass={"xsmall"} />
              

                            <UserForm
                                clearForm={this.clearForm}
                                contactForm={contactInfo}
                                handleInput={this.handleInput}
                                selectedCountry={selectedCountry}
                                width={width}
                                saveCurrentSelectedCountry={this.saveCurrentSelectedCountry}
                                country={country}
                                secureInfo={true}
                                dictionary={dictionary}
                                openThanksModal={this.openThanksModal}
                                style={{ textAlign: 'center', margin: '40px', justifyContent: 'center' }}
                            />
                        </div>)}
            </>
        )
    }


    renderContactForm() {
        let { dictionary, width, country } = this.props;
        let { contactInfo, selectedCountry } = this.state;
        const iconLock = require('../../elements/icons/s_secured.png');
        return (


            <div className="ListingPageContactForm">
                <div className={"SectionHdr"}>
                    <HOne title={dictionary.contact_for_more} addclass={"small"} />
                </div>
                {/* <div className="ListingContactDiv">
                    <div className="ContactFormInputs">
                        <div className={"NamePhone"}>
                            <input type="text" name="userFullName" className="Input " placeholder={dictionary.full_name} value={this.state.contactInfo.userFullName} onChange={this.handleInput} />
                            <input type="text" name="userPhone" className="Input" placeholder={dictionary.phone} value={this.state.contactInfo.userPhone} onChange={this.handleInput} />
                        </div>

                        <input type="text" name="userEmail" className="Input" placeholder={dictionary.email} value={this.state.contactInfo.userEmail} onChange={this.handleInput} />

                        <textarea type="text" name="userComment" className="Input DealMessage" placeholder={dictionary.message} value={this.state.contactInfo.userComment} onChange={this.handleInput} />

                    </div>
                </div>
                <div className="SecureDiv">
                    <img className="SecureIcon" src={iconLock} alt="" />
                    <span className="Text">
                        {dictionary.information_secure}
                    </span>
                </div>
                <div className="ContactFormBottom" style={this.state.contactIsSended ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }}>
                    {this.renderAnswer()}
                    <button name="BottomForm" className="Button" id="ButtonSubmit" onClick={this.handleSubmit}>{dictionary.btn_request_details}</button>

                </div> */}


                <div className="ListingContactDiv">
                    <UserForm
                        clearForm={this.clearForm}
                        topPartRow={true}
                        contactForm={contactInfo}

                        handleInput={this.handleInput}
                        width={width}
                        secureInfo={true}
                        selectedCountry={selectedCountry}
                        saveCurrentSelectedCountry={this.saveCurrentSelectedCountry}
                        dictionary={dictionary}
                        country={country}
                        openThanksModal={this.openThanksModal}
                        style={{ textAlign: 'center', justifyContent: 'center' }} />
                </div>
            </div>
        )
    }

    renderSimilars() {
        let { dictionary } = this.props;
        if (this.state.similar !== undefined) {
            return (
                <OffersGrid
                    title={dictionary.similar_properties}
                    data={this.state.similar.featured_deals}
                    width={this.props.width}
                    dictionary={dictionary}
                    lastCard={false}
                />
            )
        }

    }

    renderPageInfo() {
        let { dictionary, width } = this.props;
        let { data, analyseInfo,agent } = this.state;
        return (
            <>
                <div className="ListingPageMainInfoDiv">
                    {/* {this.renderPropertyDescription()}
                    {this.renderKeyDeal()}
                    {this.renderPropertyInformation()} */}
                    <KeyDealFeatures
                        dictionary={dictionary}
                        analyseInfo={analyseInfo}
                        data={data}
                        width={width}
                    />
                    <Description
                        dictionary={dictionary}
                        analyseInfo={analyseInfo}
                        data={data}
                    />

                        <Information
                            dictionary={dictionary}
                            data={data}
                            width={width}
                        />
                </div>

                <div className="ListingPageSignature">

                    {agent&&
                            <AgentCard
                                listing={data}
                                hugeProfile={false} 
                                width={width}
                                title={'1'}
                                notes={''}
                                agent={agent}
                                date={''}
                                disableNote={true}
                                signature={true}
                            />
                    }

                    {/* {this.renderContactForm()} */}
                </div>
                {this.renderSimilars()}
            </>
        )

    }

    render() {
        let { dictionary, width } = this.props;
        let { data, analyseInfo, similar,agent } = this.state;
        let body = null;

        if (data !== undefined && data.Media !== undefined && data.length !== 0) {
            let ParsedListingPrice = this.stringProcessingService.convertToFormat(data.ListPrice.toString());
            let ParsedSquareFeet = '';
            if (data.PropertyType !== "Land/Boat Docks" && data.LivingArea) {
                ParsedSquareFeet = this.stringProcessingService.convertToFormat(data.LivingArea.toString());
            } else if (data.LotSizeSquareFeet) {
                ParsedSquareFeet = this.stringProcessingService.convertToFormat(data.LotSizeSquareFeet.toString());
            }

            // let ParsedSquareFeet = data.PropertyType !== "Land/Boat Docks" ? this.stringProcessingService.convertToFormat(data.LivingArea.toString()) : (
            //     this.stringProcessingService.convertToFormat(data.LotSizeSquareFeet.toString())
            // )

            this.insertJSONLD();
            let metaTitle = '';
            if (analyseInfo != null && analyseInfo.rank >= 2) {
                let metaRank = '';
                switch (analyseInfo.rank) {
                    case 2: {
                        metaRank = dictionary.good_deal;
                        break;
                    }
                    case 3: {
                        metaRank = dictionary.great_deal;
                        break;
                    }
                    case 4: {
                        metaRank = dictionary.excellent_deal;
                        break;
                    }

                }
                metaTitle = `${metaRank} | ${data.City}, ${data.StateOrProvince} ${data.PostalCode}`;

            } else metaTitle = ``;
            body = (

                <div className="ListinPageBody MenuSpace">

                    <Helmet>
                        <title> {metaTitle} | Smart Luxe</title>
                        <meta name="description" content={data.PublicRemarks} />
                        {this.seoService.insertHrefLangTags(
                            {
                                path: `/listing/:id`,
                                params: {
                                    id: data.ListingId,
                                },
                            })
                        }
                    </Helmet>

                    <div className={"ListinPageHeaderLine"}>
                        <div className="ListinPageStaticHeader">
                            <div className="Title">
                                <div className={"VertLine"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64.484" viewBox="0 0 2 64.484">
                                        <defs>
                                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                <stop offset="0" stopColor="#a96903" />
                                                <stop offset="0.258" stopColor="#aa6a02" />
                                                <stop offset="0.352" stopColor="#b07102" />
                                                <stop offset="0.418" stopColor="#b97d02" />
                                                <stop offset="0.472" stopColor="#c78e01" />
                                                <stop offset="0.518" stopColor="#daa401" />
                                                <stop offset="0.557" stopColor="#f0be00" />
                                                <stop offset="0.579" stopColor="#ffd100" />
                                                <stop offset="1" stopColor="#a96903" />
                                            </linearGradient>
                                        </defs>
                                        <g id="Сгруппировать_94" data-name="Сгруппировать 94" transform="translate(-272.933 -2822.173) rotate(90)">
                                            <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="64.484" height="2" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)" />
                                        </g>
                                    </svg>
                                </div>
                                <h1>
                                    <div className="TitieAndDisc">
                                        <div>
                                            {/* 18555 Collins Avenue, Unit 4803 */}
                                            {`${data.StreetNumber} ${data.StreetName} ${data.UnitNumber !== null ? ('#' + data.UnitNumber) : ("")}`}
                                        
                                        </div>
                                        {`${data.City}, ${data.StateOrProvince} ${data.PostalCode}`}

                                    </div>
                                </h1>

                            </div>
                            <div className="MainInfoAndDealScoreAtTop">
                                <div className="MainInfo">
                                    <div className="Item">
                                        {dictionary.bedrooms}:   <br /><span>   {data.BedroomsTotal != null && data.BedroomsTotal !== '' ? data.BedroomsTotal : (`N/A`)}  </span>
                                    </div>
                                    <div className="Item">
                                        {dictionary.bathrooms}:  <br /> <span> {data.BathroomsTotalDecimal != null && data.BathroomsTotalDecimal !== '' ? data.BathroomsTotalDecimal : (`N/A`)} </span>
                                    </div>
                                    <div className="Item">
                                        {dictionary.area_metric_full}:  <br /> <span> {ParsedSquareFeet != null && ParsedSquareFeet !== '' ? ParsedSquareFeet : (`N/A`)}</span>
                                    </div>
                                    <div className="Item Last PriceInRow Price">
                                        <div>
                                            {dictionary.listing_price}:  <br /> <span>${(ParsedListingPrice != null && ParsedListingPrice !== '') ? (ParsedListingPrice) : (`N/A`)}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className={"DealScoreAtTop"} onClick={this.handleDealScore}>
                                    {/* {this.renderDealScore(true)} */}
                                    <DealScore
                                        dictionary={dictionary}
                                        width={width}
                                        for_top={true}
                                        analyseInfo={analyseInfo}
                                        handleDealScore={this.handleDealScore}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"PageListingLine"}>
                        <div className="PageListing">
                            {/* media */}
                            <div className="MediaMain" >
                                <div className="MediaBlock">
                                    {this.renderMedia()}
                                </div>
                                <div className="DealScoreAndContacts" id={agent?'AgentContact':''}>
                                    {/* {this.renderDealScore()} */}
                                    <DealScore
                                        dictionary={dictionary}
                                        width={width}
                                        analyseInfo={analyseInfo}
                                        handleDealScore={this.handleDealScore}
                                    />
                                    {this.renderDealScoreContactForm()}
                                </div>
                            </div>

                            {this.renderPageInfo()}
                        </div>
                    </div>
                    <FindMyDealBlock dictionary={dictionary} />
                    
                  
                    
                    <ThanksModal
                        isVisible={this.state.isVisibleThanksModal}
                        title={dictionary.thank_you}
                        message={dictionary.thank_you_deal}
                        closeModal={this.closeModal}
                    />

                </div>
            )

        }
        else {
            body = (
                <div className="EmptyBody">

                </div>
            )
        }
        return (
            <>
                {body}
            </>
        )
    }
}
export default withRouter(ListingPage);