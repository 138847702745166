import React from 'react';

import {
    BrowserRouter as Router,
    withRouter,
} from "react-router-dom";

import './custom-landing-page-style.css'
import UserForm from '../user-form';
import ServerApi from '../../services/server-api-service'


const initialState = {
    contactInfo: {
        userComment: "",
        userEmail: "",
        userFullName: "",
        userPhone: "",
        project: '',
    },
    customDictionary:null
}

class CustomLandingPage extends React.Component{
    serverApi = new ServerApi();
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentWillMount() {
        this.serverApi.getCustomDictionary(this.props.match.params.name)
        .then((data)=>{
            this.setState({
                customDictionary:data
            })
        })
        .catch((error)=>{
            console.log('ERROR with fetch getCustomDictionary',error);
        })  
        this.props.saveState({
            currentPage:"CustomLandingPage",
            isVisibleFooter: false,
            isVisibleHeader: false,
        })
    }

    componentWillUnmount() {
        this.props.saveState({
            isVisibleFooter: true,
            isVisibleHeader: true,
        })
    }

    clearForm = () => {
        this.setState({
            ...this.state,
            contactInfo: {
                userComment: "",
                userEmail: "",
                userFullName: "",
                userPhone: ""
            }
        })
    }

    handleInput = (event) => {
        this.setState({
            contactInfo: {
                ...this.state.contactInfo,
                [event.target.name]: event.target.value
            }
        })
    }




    // useEffect(() =>  console.log('mount') () => , []);
    render() {

        let {contactInfo,customDictionary} = this.state;
        let {dictionary,width,country} = this.props;

        const smart_luxe_logo_small = require('../../elements/images/invest/smart_luxe_logo_small.png');
        const investLogo = require('../../elements/images/invest/invest-logo.svg').default;
        const approved = require('../../elements/images/invest/approved.png');
        const time_to_act = require('../../elements/images/invest/time_to_act.jpg');
        const logo_small = require('../../elements/images/invest/logo-small.png');

        const proj_1 = require('../../elements/images/invest/proj_1.jpg');
        const proj_2 = require('../../elements/images/invest/proj_2.jpg');
        const proj_3 = require('../../elements/images/invest/proj_3.jpg');
        const proj_4 = require('../../elements/images/invest/proj_4.jpg');
        const proj_5 = require('../../elements/images/invest/proj_5.jpg');
        const proj_6 = require('../../elements/images/invest/proj_6.jpg');

        const sold_out = require('../../elements/images/invest/sold_out.png');

        const vrbo_logo = require('../../elements/images/invest/vrbo_logo.png');
        const airbnb_logo = require('../../elements/images/invest/airbnb_logo.png');

        const sm_logo = require('../../elements/images/invest/sm_logo.png');

        const smart_luxe_logo_but = require('../../elements/images/invest/smart_luxe_logo_but.png');

        if(customDictionary== null)return null;

        return (
            <div className="customCampaigns">
                <div className={"header"}>
                    <img src={investLogo} className={"investLogo"} alt={"Invest in Miami"} />
                    <div className={"smartLuxeLogo"}>
                        Presented by: <img src={smart_luxe_logo_small} alt={""} />
                    </div>
                </div>
                <div className={"separator"}></div>
                <div className={"contactFormBlock"}>
                    {/* <div className={"contactForm"}>
                        <div className={"contactFormHeader"}>Immediate Cashflow Opportunities</div>
                        <input type={"text"} placeholder={"Your name"}></input>
                        <input type={"text"} placeholder={"Your phone number"}></input>
                        <input type={"text"} placeholder={"Your email"}></input>
                        <textarea></textarea>
                        <div className={"requestButton"}>Request a consultation</div>
                    </div> */}

                    <UserForm
                        formTitle={customDictionary.cash}
                        customLandingForm={true}
                        clearForm={this.clearForm}
                        topPartRow={true}
                        contactForm={contactInfo}
                        handleInput={this.handleInput}
                        width={width}
                        message={dictionary.thank_you_deal}
                        country={country}
                        thankModal={true}
                        secureInfo={true}
                        dictionary={dictionary}
                        className={'Form'}
                        CustomInputStyle={{ padding: '0 0 0 5px' }}
                        style={{ textAlign: 'center', display: 'block', justifyContent: 'center' }} />

                    <img className={"approved"} alt={"Approved"} src={approved} />
                </div>
                <div className={"timeToAct"}>
                    <div className={"timeToActInt"}>
                        <h2>{customDictionary.act}</h2>
                        <div className={"listAndImage"}>
                            <div className={"list"}>
                                <div className="pointed item">
                                    <img src={logo_small} alt="" />
                                    {customDictionary.cash}
                                </div>
                                <div className={"pointed item"}>
                                    <img src={logo_small} alt="" />
                                    {customDictionary.financing}
                                </div>
                                <div className="item">
                                    <img src={logo_small} alt="" />
                                    Strong population and 5% economic growth
                                </div>
                                <div className="item">
                                    <img src={logo_small} alt="" />
                                    {customDictionary.world_class}
                                </div>
                                <div className="item">
                                    <img src={logo_small} alt="" />
                                    {customDictionary.green}
                                </div>
                            </div>
                            <img className={"illustration"} src={time_to_act} alt="illustration" />
                        </div>
                    </div>
                </div>
                <div className={"featuredProjects"}>
                    <div className={"featuredProjectsInt"}>
                        <h2>Featured Short-Term Rental Projects</h2>
                        <div className={"projects"}>
                            <div className={"project"}>
                                <div className={"name"}>Natiivo</div>
                                <div className={"image"}>
                                    <img src={proj_1} alt="" />
                                    <img src={sold_out} className={"soldOut"} alt="" />
                                </div>
                            </div>
                            <div className={"project"}>
                                <div className={"name"}>NEXO</div>
                                <div className={"image"}>
                                    <img src={proj_2} alt="" />
                                </div>
                            </div>
                            <div className={"project"}>
                                <div className={"name"}>Legacy</div>
                                <div className={"image"}>
                                    <img src={proj_3} alt="" />
                                </div>
                            </div>
                            <div className={"project"}>
                                <div className={"name"}>Natiivo</div>
                                <div className={"image"}>
                                    <img src={proj_4} alt="" />
                                </div>
                            </div>
                            <div className={"project"}>
                                <div className={"name"}>The Crosby</div>
                                <div className={"image"}>
                                    <img src={proj_5} alt="" />
                                    <img src={sold_out} className={"soldOut"} alt="" />
                                </div>
                            </div>
                            <div className={"project"}>
                                <div className={"name"}>THE ELSER</div>
                                <div className={"image"}>
                                    <img src={proj_6} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className={"webLogos"}>
                            <img src={vrbo_logo} alt="" />
                            <img src={airbnb_logo} alt="" />
                        </div>
                    </div>
                </div>
                <div className={"smartLuxe"}>
                    <img src={sm_logo} alt="SmartLuxe" />
                    <div className={"slogan"}>
                        Smart Luxe Helps Savvy Real Estate Investors Find The Best<br /> Deals In Miami
                    </div>
                </div>
                <div className={"formSecond"}>
                    {/* <div className={"contactForm"}>
                        <div className={"contactFormHeader"}></div>
                        <input type={"text"} placeholder={"Your name"}></input>
                        <input type={"text"} placeholder={"Your phone number"}></input>
                        <input type={"text"} placeholder={"Your email"}></input>
                        <textarea></textarea>
                        <div className={"requestButton"}>Request a consultation</div>
                    </div> */}

                    <UserForm
                        customLandingForm={true}
                        clearForm={this.clearForm}
                        topPartRow={true}
                        contactForm={contactInfo}
                        handleInput={this.handleInput}
                        width={width}
                        message={dictionary.thank_you_deal}
                        country={country}
                        thankModal={true}
                        secureInfo={true}
                        dictionary={dictionary}
                        className={'Form'}
                        CustomInputStyle={{ padding: '0 0 0 5px' }}
                        style={{ textAlign: 'center', display: 'block', justifyContent: 'center' }} />
                </div>
                <div className={"Footer"}>
                    <div className={"footerTop"}>
                        <img src={smart_luxe_logo_but} alt='Logo' className={"logo"} />
                        <div className={"phone"}>+1 (305) 760 4884</div>
                        <div className={"contacts"}>
                            NG International Realty LLC (DBA SmartLuxe)<br />
                            450 N. Park Road #801 Hollywood, FL 33021<br />
                            +1 305-760-4884<br />
                            info@smartluxe.com
                            <div className={"vertline"}></div>
                        </div>
                    </div>
                    <div className={"footerBottom"}>
                        <div className={"disclaimer"}>Disclaimer:  This website is independently operated by SmartLuxe, it is intended for potential buyers and it has no affiliation with the developer.</div>
                        <div className={"notes"}>{dictionary.disclaimer} {dictionary.copyright} {new Date().getFullYear()}.</div>
                        <div className={"vertlineFooter"}></div>
                    </div>
                </div>
            </div>)
    }
}

export default  withRouter(CustomLandingPage);